import { ControlLabel, FormGroup } from '@freecodecamp/react-bootstrap';
import { kebabCase } from 'lodash-es';
import React from 'react';
import { Field } from 'react-final-form';
import Input from '@ui/Input';

export type FormOptions = {
  ignored?: string[];
  isEditorLinkAllowed?: boolean;
  isLocalLinkAllowed?: boolean;
  required?: string[];
  types?: { [key: string]: string };
  placeholders?: { [key: string]: string };
};

type FormFieldsProps = {
  readonly formFields: { name: string; label: string }[];
  readonly options: FormOptions;
  readonly isValidGithubURL: boolean | null;
  readonly setIsValidGithubURL: (isValid: boolean | null) => void;
};

function checkIsValidGitHubURL(url: string) {
  const regex = /^https:\/\/github\.com\/([a-zA-Z0-9-]+)\/([a-zA-Z0-9-_]+)$/;
  return regex.test(url);
}

function FormFields(props: FormFieldsProps): JSX.Element {
  const {
    formFields,
    options = {},
    isValidGithubURL,
    setIsValidGithubURL
  }: FormFieldsProps = props;

  const {
    ignored = [],
    placeholders = {},
    required = [],
    types = {}
  } = options;

  return (
    <>
      {formFields
        .filter(formField => !ignored.includes(formField.name))
        .map(({ name, label }) => (
          <Field key={`${kebabCase(name)}-field`} name={name}>
            {({ input: { value, onChange } }) => {
              const key = kebabCase(name);
              const type = name in types ? types[name] : 'text';
              const placeholder =
                name in placeholders ? placeholders[name] : '';

              const handleValidateGithubURL = (
                e: React.ChangeEvent<HTMLInputElement>
              ) => {
                const value = e.target.value;
                onChange(e);
                setIsValidGithubURL(checkIsValidGitHubURL(value));
              };

              return (
                <FormGroup key={key}>
                  {type === 'hidden' ? null : (
                    <ControlLabel htmlFor={key}>{label}</ControlLabel>
                  )}
                  <Input
                    id={key}
                    name={name}
                    onChange={handleValidateGithubURL}
                    placeholder={placeholder}
                    required={required.includes(name)}
                    rows={4}
                    type={type}
                    value={value as string}
                    error={
                      typeof isValidGithubURL === 'boolean' &&
                      !isValidGithubURL &&
                      'Link inválido. Certifique-se de incluir a URL válida do repositório correto do GitHub'
                    }
                  />
                </FormGroup>
              );
            }}
          </Field>
        ))}
    </>
  );
}

FormFields.displayName = 'FormFields';

export default FormFields;
